<template>
  <div>
    <div class="text-center font-weight-bold" v-if="!getChosenPlan">
      Sorry we lost your calculation, Please retry !
      <div>
        <v-btn
          depressed
          class="my-4"
          color="#00b08d"
          dark
          rounded
          @click="
            $router.push({
              name: 'Calculator',
            })
          "
          >back to Calculator</v-btn
        >
      </div>
    </div>
    <div v-if="getChosenPlan">
      <div class="text-header mb-10">Go To Payment</div>
      <PlanCard :product="getChosenPlan" :showCTAButton="false" />
      <!-- <v-text-field
        label="Got a free trees promo code?"
        rounded
        filled
        color="grey"
        class="py-0 my-0"
        dense
        v-model="promoCode"
      ></v-text-field> -->
      <v-btn
        class="my-4 white--text"
        rounded
        color="#00b08d"
        large
        block
        :loading="isLoading"
        :disabled="isLoading || !sessionId || hasError"
        @click="submitCheckout"
        >Go to payment</v-btn
      >
      <!-- strip checkout component -->
      <stripe-checkout
        ref="checkoutRef"
        :pk="publishableKey"
        :session-id="sessionId"
      />
    </div>
  </div>
</template>

<script>
import AuthMixin from "@/mixins/AuthMixin.vue";

import PlanCard from "@/components/choose-plan/PlanCard.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ApiService } from "@/services/ApiService";
import { StripeCheckout } from "@vue-stripe/vue-stripe";

export default {
  name: "ChoosePaymentMethod",
  mixins: [AuthMixin],
  components: {
    PlanCard,
    StripeCheckout,
  },
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;

    return {
      promoCode: null,
      isLoading: false,
      hasError: false,
      sessionId: null,
    };
  },
  async created() {
    // set the app bar title
    this.setCurrentAppBarTitle("Payment");

    // check beforeEnter conditions first
    await this.beforeEnterMiddleware();

    await this.createSession();
  },
  computed: {
    ...mapGetters(["getChosenPlan", "getCurrentUser", "getUserFSDocument"]),
  },
  methods: {
    ...mapMutations(["setErrorSnackbarMessage", "setCurrentAppBarTitle"]),
    ...mapActions(["fetchUserFSDocument"]),

    async beforeEnterMiddleware() {
      if (!this.getChosenPlan) {
        // redirect to choose plan, if user has no plan selected
        this.$router.push({
          name: "ChoosePlan",
        });
      }

      try {
        this.isLoading = true;
        await this.fetchUserFSDocument();
      } catch (error) {
        console.log(error);
        this.setErrorSnackbarMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    async createSession() {
      try {
        this.isLoading = true;
        const response = await ApiService.post(
          "/payment/createNewSubscriptionSession",
          {
            // customer details
            uid: this.getCurrentUser.uid,
            email: this.getCurrentUser.email || this.getUserFSDocument.email,
            //plan data
            ...this.getChosenPlan,
          }
        );
        this.sessionId = response.data;
      } catch (error) {
        this.hasError = true;
        this.setErrorSnackbarMessage(error?.response?.data?.message || error);
      } finally {
        this.isLoading = false;
      }
    },
    submitCheckout() {
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>

<style scoped>
</style>